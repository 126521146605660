import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import liferayAPI from '../../apiLiferay';
import parse from 'html-react-parser';

import Header from '../general/Header';
import Footer from '../general/Footer';
import AcessoRapido from '../general/AccesoRapidoComponent';

import IconAlert from '../../images/icons/icon-incidencia.svg';
import IconInfo from '../../images/icons/icon-informacion.svg';
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class AlertasComponent extends Component {
	 constructor(props) {
		    super(props);
		    this.state = {
		      version: "alertas",
		      view: "no_list",
		      showSelections: "none",
		      contenidosGeneralLiferay: [],
		      contenidosLiferay:[],
		      locale: "eu_ES"
		    }; 
      }
	 
	 componentDidMount(){
		const cookies = new CookiesUniversal();
	 	if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
	 		$('#modalOpenCookies').modal('show') 
		}
	 		
		this.cookies()
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}
		
		liferayAPI.getArticlesContenidoAlertasLiferay(this.props.i18n.language, process.env.REACT_APP_SCTRUCTURE_ID_ALERTAS).then(response => 
		this.setState({
			 contenidosGeneralLiferay: response.items
	       }
		));
	
		 
		 liferayAPI.getArticlesContenidoLiferay(this.props.i18n.language, process.env.REACT_APP_STRUCTURE_ID_OPERADOR).then(response => 
		 	this.setState({
		 		contenidosLiferay: response.items
		 	}));
		 
		 if(document.getElementById('planificador')!==null && document.getElementById('lineas')!==null){
			 document.getElementById('planificador').classList.remove('active');document.getElementById('lineas').classList.remove('active');  
		 }	
		
	 }
	 
	 cookies(){
			var {t} = this.props;
		   	
			var dir = window.location.origin+"/"+"#";
			const pathname = window.location.href.substring(dir.length); 
			
			const cookies = new CookiesUniversal();
			var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
		     
			if(cookieAnalitica ==="true"){
				ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
//				ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuAlertas')); 
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuAlertas'), tracking: 'defaultTracker' });

			}else if(cookieAnalitica ==="false") {
				ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
//				ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuAlertas'));
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuAlertas'), tracking: 'anonymousTracker' });

			}
	}
	
	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
	    this.setState({ view: view});
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	    	this.setState({ showSelections: "block" });
	    }else{
	    	this.setState({ showSelections: "none" });
	    }
	}
	
	getTitle(){
		const regex = /[^\w\s]/gi;

		const unique = (value, index, self) => {
			  return self.indexOf(value) === index
		}
		
		let content = []
 			
		for(var i=0; i<this.state.contenidosGeneralLiferay.length; i++){
			var operador = this.state.contenidosGeneralLiferay[i].contentFields[5].contentFieldValue.data.replace(regex, "");
//			content.push(this.state.contenidosGeneralLiferay[i].contentFields[5].contentFieldValue.data.replace(regex, ""))
			content.push(operador.charAt(0).toUpperCase() + operador.replace(regex, "").slice(1))

		}
		
		const uniqueTitle = content.filter(unique) 
		 
		return uniqueTitle.sort();
	}
	
	getFecha(contenido){
		 var fullDate = contenido.datePublished;
		 var dt = new Date(fullDate);
		 
		 var datestring; 
			
		 if(this.props.i18n.language ==='eu'){
			 datestring = dt.getFullYear()  + "/" + (dt.getMonth()+1) + "/" + dt.getDate() + " - " +
				 dt.getHours() + ":" + (dt.getMinutes()<10?'0'+dt.getMinutes():dt.getMinutes())+"h";
		 }else{
			 datestring = dt.getDate()  + "/" + (dt.getMonth()+1) + "/" + dt.getFullYear() + " - " +
				 dt.getHours() + ":" +(dt.getMinutes()<10?'0'+dt.getMinutes():dt.getMinutes())+"h";
		 }
			 
		return datestring;
	}
	

  
	render() {
		const regex = /[^\w\s]/gi;

	  const { t } = this.props;
	  let general; 
	  var operador, operadorF;
	  if (this.state.contenidosGeneralLiferay !== undefined ){
		  
		  general = this.getTitle().map((contenidoCategoria, indexC) => ( 
				 
				  contenidoCategoria.includes('Generales') && 
				  <React.Fragment key={indexC}>
				  <h2 className="title">{contenidoCategoria ==='generales'?t('keys.generales'):contenidoCategoria}</h2>
				  <ul className="list_alerts">
					{
						
						this.state.contenidosGeneralLiferay.map((contenido, indexG) =>(
							operador = contenido.contentFields[5].contentFieldValue.data.replace(regex, ""),
							operadorF = operador.charAt(0).toUpperCase() + operador.replace(regex, "").slice(1),
							operadorF.includes(contenidoCategoria) &&
							<li className={contenido.contentFields[4].contentFieldValue.data.includes("alerta")?"item_alerta info":"item_alerta"} key={contenido.key}>
								<img className="icono_notificacion" src={contenido.contentFields[4].contentFieldValue.data.includes("alerta")?IconAlert:IconInfo} alt=""/>
								<div className="text">
								
	                            	<h3 className="subtitle">{parse(contenido.contentFields[0].contentFieldValue.data)}</h3>
	                            	<p className="time">{this.getFecha(contenido)}</p>
	                            </div>
	                            <span className="material-icons icon" aria-hidden="true">chevron_right</span>
	                            <a className="alert_link" href={"#/alertas_detalle/"+contenido.key} rel="noopener noreferrer" title="(Abre una nueva ventana)">
	                                <span className="sr-only">{contenido.key}</span>
	                            </a>
							</li>
							
						))
					}
	                
					</ul> 
					</React.Fragment>
					
		  ));
	  }
	  
	  let title;
	  if (this.state.contenidosGeneralLiferay !== undefined){
		  
		  title = this.getTitle().map((contenidoCategoria, indexC) => (
				  !contenidoCategoria.includes('Generales') && 
				  <React.Fragment key={indexC}>
				  <h2 className="title">{contenidoCategoria}</h2>
				  <ul className="list_alerts" >
					{
						this.state.contenidosGeneralLiferay.map((contenido, index) =>( 
								operador = contenido.contentFields[5].contentFieldValue.data.replace(regex, ""),
								operadorF = operador.charAt(0).toUpperCase() + operador.replace(regex, "").slice(1),
								operadorF.includes(contenidoCategoria) &&
							
							<li className={contenido.contentFields[4].contentFieldValue.data.includes("alerta")?"item_alerta info":"item_alerta"} key={contenido.key}>
								<img className="icono_notificacion" src={contenido.contentFields[4].contentFieldValue.data.includes("alerta")?IconAlert:IconInfo} alt=""/>
								<div className="text">
								{
									contenido.contentFields[0].contentFieldValue.data === "" &&
									<h3 className="subtitle">{contenido.title}</h3> 
								}
								{
									contenido.contentFields[0].contentFieldValue.data !== "" && 
									<h3 className="subtitle">{parse(contenido.contentFields[0].contentFieldValue.data)}</h3>
								}	
	                            	<p className="time">{this.getFecha(contenido)}</p>
	                            </div>
	                            <span className="material-icons icon" aria-hidden="true">chevron_right</span>
	                            <a className="alert_link" href={"#/alertas_detalle/"+contenido.key} rel="noopener noreferrer" title="(Abre una nueva ventana)">
	                                <span className="sr-only">{contenido.contentFields[1].contentFieldValue.data}</span>
	                            </a>
							</li>
							
						))
					}
	                
					</ul> 
					</React.Fragment>
					
		  ));
	  }
 
	  return(
			  <React.Fragment>
				<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuAlertas')}  onSelectLanguage={this.handleLanguage} /> 
		    		<div className={this.state.version+ " section_content_alerts"} id="main-content">
		    		    <h1 className="sr-only no_mobile">{t('keys.menuAlertas')}</h1> 		     
		    		      
                        	<div className="container">
	                        	{
		   		    		    	 title.length<1 &&  general.length<1 &&
		   		    		    	 <p className="text_description">{t('keys.sinAlertas')}</p>
	   		    		     	}
	                            {general}
	                            {title}
                            </div> 
                         
                        <button type="button" className="button_confi" onClick={()=> window.location.href="/#/configuracion"}>
                            <span className="material-icons icon" aria-hidden="true">settings</span>
                            <span className="text">{t('keys.configuracionNotificaciones')}</span>
                        </button>
		    		</div>
		    		<AcessoRapido/>
		    	<Footer />
		    </React.Fragment>
    );
  }
}
export default withTranslation()(AlertasComponent);
