import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';

import { Link } from 'react-router-dom';

import liferayAPI from '../../apiLiferay';
import apiLineas from '../../apiLineas';
import pushWooshAPI from '../../apiPushWoosh';

import Header from '../general/Header';
import Footer from '../general/Footer';

import GuardarLineas from '../lineas/GuardarLineaComponent';
import SeccionTarifas from './SeccionTarifasComponent';
import SeccionAyuda from './SeccionAyudaComponent';
import CompartirLinea from './CompartirLineaComponent';

import IconHorariosLineas from '../../images/icons/icon_horarios_lineas.svg';
import IconHorariosLineasRed from '../../images/icons/icon_horarios_lineas_red.svg';

import IconTarifasLineas from '../../images/icons/icon_tarifas_lineas.svg';
import IconTarifasLineasRed from '../../images/icons/icon_tarifas_lineas_red.svg';

import IconAyudaLineas from '../../images/icons/icon_ayuda_lineas.svg';
import IconAyudaLineasRed from '../../images/icons/icon_ayuda_lineas_red.svg';

import IconClose from '../../images/icons/close.png';
import IconTwitter from '../../images/icons/icon_twitter.svg';
import IconFacebook from '../../images/icons/icon_facebook.svg'
import IconVuelta from '../../images/icons/icon_change_linea.svg';
import IconDownload from '../../images/icons/button_download_red.svg';

import IconEndLinea from '../../images/icons/icon_end_linea.svg';
import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';
		
import IconAlarmLine from '../../images/icons/icon_alarm_line.svg';
 
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class LineasInfoDetalleComponent extends Component {

	constructor(props) {
	    super(props);
		
	    this.state = {
	      version: "detalleLinea",
	      view: "no_list",
	      showSelections: "none",
	      facebook:IconFacebook,
		  twitter:IconTwitter,
	      datosParadas:[],
	      contenidosLiferay:[],
	      locale: "eu_ES",
	   	  isAlphabetic: false,
	   	  markers: [],
	   	  horarioInitFin: "",
	   	  horarioInitSelected: "",
	   	  horarioFinSelected: "",
          isOpen: "",
	   	  defaultZoom: 12,
	   	  letraAnterior:'',
	   	  showHeadsign: "false",
	   	  twoWay: "false",
	   	  showHorarios: "true",
	   	  showChangeDirection: "false",
	   	  seeMap:"",
	   	  downloadMap:"",
	   	  dir: 0,
	   	  mapCenter: {
	   		lat: 43.2633182,
	   	  	lng: -2.9685838
	   	  },
	   	  bounds: null,
	   	  hasAlert: false,
	   	  alert: null
	    };
	    this.checkRecentsLines = this.checkRecentsLines.bind(this);
	    this.changeDirection = this.changeDirection.bind(this);
	    this.onToggleOpen = this.onToggleOpen.bind(this);
	    this.cookies = this.cookies.bind(this);
    }

	componentDidMount() {
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    } 
		
		this.cookies();
		const regex = /[^\w.\s]/gi;
		
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		 }

		if(this.props.match.params.tab !== undefined){
			this.changeStyle(this.props.match.params.tab);
		}

		if (this.props.match.params.linea.trim() !== "") {
			this.checkRecentsLines(this.props.match.params.operador, this.props.match.params.linea);
			this.checkAlert(this.props.match.params.operador, this.props.match.params.linea);
		} else {
			this.checkRecentsLines(this.props.match.params.operador, this.props.match.params.ruta);
			this.checkAlert(this.props.match.params.operador, this.props.match.params.ruta);
		}

		liferayAPI.getArticlesContenidoLiferay(this.props.i18n.language, process.env.REACT_APP_STRUCTURE_ID_OPERADOR).then(response => {
			this.setState({
				contenidosLiferay: response.items
			}, function () {
				var isAlphabetic = "false";
				for (var i = 0; i< this.state.contenidosLiferay.length; i++) {
					if (this.props.match.params.operador.toString() === this.state.contenidosLiferay[i].contentFields[1].contentFieldValue.data.replace(regex, "")) {
						isAlphabetic = this.state.contenidosLiferay[i].contentFields[5].contentFieldValue.data;
						this.state.twoWay = this.state.contenidosLiferay[i].contentFields[6].contentFieldValue.data;
						this.state.showHeadsign = this.state.contenidosLiferay[i].contentFields[7].contentFieldValue.data;
						this.state.showHorarios = this.state.contenidosLiferay[i].contentFields[8].contentFieldValue.data;
						this.state.showChangeDirection = this.state.contenidosLiferay[i].contentFields[9].contentFieldValue.data;						
						if (this.state.contenidosLiferay[i].contentFields[12].contentFieldValue.hasOwnProperty("image")){
							this.state.seeMap = this.state.contenidosLiferay[i].contentFields[12].contentFieldValue.image.contentUrl;
							
						}else{
							this.state.seeMap = "";
						}
						
						if (this.state.contenidosLiferay[i].contentFields[13].contentFieldValue.hasOwnProperty("document")){
							this.state.downloadMap = this.state.contenidosLiferay[i].contentFields[13].contentFieldValue.document.contentUrl;
						}else{
							this.state.downloadMap = "";
						}
						
						break;
					}
				}
				if(isAlphabetic === "true") {
					apiLineas.getParadasAlfabeticas(this.props.match.params.operador, this.props.match.params.idRoute, 1).then((paradas) => {
						this.setState(() => ({
							datosParadas: this.state.datosParadas.concat(paradas.contenido),
							isAlphabetic: true
						}), function () {
                            this.checkAvaibleSchedule();
                            this.fitBounds(this.state.datosParadas);
                        });
					});
				} else {
					
					apiLineas.getParadasSecuencial(this.props.match.params.operador, this.props.match.params.idRoute, 0, "").then((paradas) => {
 							if(paradas.contenido.length > 0) {
 
								this.setState(() => ({
							   		datosParadas: this.state.datosParadas.concat(paradas.contenido)
		                        }), function () {
		                            this.checkAvaibleSchedule();
		                            this.fitBounds(this.state.datosParadas);
		                             
		                        });	
							} else {
								apiLineas.getParadasSecuencial(this.props.match.params.operador, this.props.match.params.idRoute, 1, "").then((paradasDir) => {
									this.setState(() => ({
								   		datosParadas: this.state.datosParadas.concat(paradasDir.contenido)
			                        }), function () {
			                            this.checkAvaibleSchedule();
			                            this.fitBounds(this.state.datosParadas);
			                        });
			                    });
							}
 					});
					
				}
				
			})
		});
	}
 
	
	cookies(){
		var {t} = this.props;
	   	
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 	
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
 
		if(cookieAnalitica ==="true"){
//			ReactGA.pageview(pathname, ['defaultTracker'], t('keys.lineas'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.lineas'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
//			ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.lineas'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.lineas'), tracking: 'anonymousTracker' });

		}
	}
	
	fitBounds (paradas) {

		this.state.bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i< paradas.length; i++) {
        	 this.state.bounds.extend(new window.google.maps.LatLng(paradas[i].stopLat, paradas[i].stopLon));
        }
        this.setState(() => ({bounds : this.state.bounds}))
       
	}
	
	checkRecentsLines(operador, linea) {

		const maxElements = 4;
		var setLines = new Set();
		let lines = JSON.parse(localStorage.getItem('lineasRec'));
 		if (lines != null) {
		    lines.forEach(element => setLines.add(JSON.stringify(element)));
		}
	    var line =  "";
	    
    	if(operador === "Renfe Cercanias" || operador === "Leintz Garraioak SL" ){
    		line ='{"operador":"' + operador + '","nombreLinea":"' + this.props.match.params.ruta + '"}';
		}else{
    		line ='{"operador":"' + operador + '","nombreLinea":"' + linea + '"}';
		}
	    	
	    if (setLines.has(line))
	    	setLines.delete(line)

	    if (linea.trim() !== "")
	    	setLines.add(line);

	    var arrayLines = [];
	    setLines.forEach(x => arrayLines.push(x));
	    for (let i = arrayLines.length; i > maxElements; i--)
	      arrayLines.shift();

	    localStorage.setItem('lineasRec', '[' + arrayLines + ']');
	}

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}

	handlerModeView = (view) => {
	    this.setState({ view: view});
	}

	handlerSelectionToogle = () => {

        if(this.state.showSelections === "none"){
            this.setState({ showSelections: "block" });
        }else{
            this.setState({ showSelections: "none" });
        }
    }

	changeStyle =(text) =>{
		if(text==="horarios"){
			document.getElementById("horarios").classList.remove('hidden');
			document.getElementById("tarifas").classList.add('hidden');
			document.getElementById("ayuda").classList.add('hidden');
			document.getElementById('tarifasLi').classList.remove('active');
			document.getElementById('horariosLi').classList.add('active');
			document.getElementById('ayudaLi').classList.remove('active');
		}else if(text==="tarifas"){
			document.getElementById("tarifas").classList.remove('hidden');
			document.getElementById("horarios").classList.add('hidden');
			document.getElementById("ayuda").classList.add('hidden');
			document.getElementById('tarifasLi').classList.add('active');
			document.getElementById('horariosLi').classList.remove('active');
			document.getElementById('ayudaLi').classList.remove('active');
		}else{
			document.getElementById("ayuda").classList.remove('hidden');
			document.getElementById("horarios").classList.add('hidden');
			document.getElementById("tarifas").classList.add('hidden');
			document.getElementById('tarifasLi').classList.remove('active');
			document.getElementById('horariosLi').classList.remove('active');
			document.getElementById('ayudaLi').classList.add('active');
		}
		
	}

	saveLine = (operador, linea) => {

		console.log("linea:  " + linea);
		console.log("linea long:  " + this.props.match.params.ruta); 
		
		var lineas = localStorage.getItem('lineas');
        var setLineas = new Set();

        if(lineas !== null && lineas !== undefined) {
       		var linesObject = JSON.parse(lineas);
       		linesObject.forEach(element => setLineas.add(JSON.stringify(element)));
       		localStorage.removeItem('lineas');
        }

		var lineName;
		
		if (linea.trim() !== "") {
			if(operador === "Renfe Cercanias" || operador === "Leintz Garraioak SL"){
				lineName = this.props.match.params.ruta;
			}else{
				lineName = linea;	
			}		
		}else {
			lineName = this.props.match.params.ruta;
		}

		var lineObject = {
			operador: operador,
			nombreLinea: lineName
		}

		setLineas.add(JSON.stringify(lineObject));

		var arrayLineas = [];
		setLineas.forEach(x => arrayLineas.push(x));

        localStorage.setItem('lineas', '[' + arrayLineas + ']');
        pushWooshAPI.setPushWooshTags();
	}

	showSelectedLine(parada, index, stopId, lat, lng){
        var elementClicked = document.getElementById('button_parada_'+index);

        var todayDate = new Date();
        var date = todayDate.getFullYear()+'/'+todayDate.getDate()+'/'+(todayDate.getMonth()+1)+" "+todayDate.getHours() + ":" + todayDate.getMinutes();
         
        if(parada !== undefined){

            var directionId = 0;

            if(parada.directionId !== undefined){
                directionId = parada.directionId;
            }
 
            apiLineas.getHorarios(parada.agencyName, parada.routeId, parada.stopId, directionId, date).then((horarios) => {

                console.log("showSelectedLine horarios")
                console.log(horarios)
                console.log("tamaño de horarios contenidos "+horarios.contenido.length)

                var horarioInicio = "";
                var horarioFin = "";

                if(horarios.contenido.length > 0){
                    horarioInicio = horarios.contenido[0].arrivalTime;
                    this.setState({
                        horarioInitSelected: horarioInicio.replace(/:[^:]*$/,'')
                    })
                }
                
                if(horarios.contenido.length > 1) {
                	horarioFin = horarios.contenido[1].arrivalTime;
                	this.setState({
                        horarioFinSelected: horarioFin.replace(/:[^:]*$/,'')
                    })
                }
            });

          
            for (var li of document.querySelectorAll("li.active")) {
                li.classList.remove("active");
            }
            elementClicked.closest(".item").classList.add("active");
            this.setState({
                isOpen: stopId,
                mapCenter: {lat: lat, lng: lng},
                defaultZoom: 14
             });
        }
	}
	
	showSelectedLineAlfabetic(parada, index, stopId, lat, lng){ 
		
        var todayDate = new Date();
        var date = todayDate.getFullYear()+'/'+todayDate.getDate()+'/'+(todayDate.getMonth()+1)+" "+todayDate.getHours() + ":" + todayDate.getMinutes();
         
        if(parada !== undefined){
        	  var directionId = 0;

              if(parada.directionId !== undefined){
                  directionId = parada.directionId;
              }
 
            apiLineas.getHorarios(parada.agencyName, parada.routeId, parada.stopId, directionId, date).then((horarios) => {

                console.log("showSelectedLine horarios")
                console.log(horarios)
                console.log("tamaño de horarios contenidos "+horarios.contenido.length)

                var horarioInicio = "";
                var horarioFin = "";

                if(horarios.contenido.length > 0){
                    horarioInicio = horarios.contenido[0].arrivalTime;
                    this.setState({
                        horarioInitSelected: horarioInicio.replace(/:[^:]*$/,'')
                    })
                }
                
                if(horarios.contenido.length > 1) {
                	horarioFin = horarios.contenido[1].arrivalTime;
                	this.setState({
                        horarioFinSelected: horarioFin.replace(/:[^:]*$/,'')
                    })
                }
            });

            var elementClicked = document.getElementById('button_parada_'+index);
            
            for (var li of document.querySelectorAll("li.active")) {
                li.classList.remove("active");
            }
            elementClicked.closest(".parada").classList.add("active");
           
            this.setState({
                isOpen: stopId,
                mapCenter: {lat: lat, lng: lng},
                defaultZoom: 14
             });
        }
	}

	checkAvaibleSchedule(){
		var {t} = this.props;
        var horarioInitFin = "";
        var todayDate = new Date();
        todayDate.setHours(0,0,0,0);
        var date = todayDate.getFullYear()+'/'+todayDate.getDate()+'/'+(todayDate.getMonth()+1)+" "+todayDate.getHours() + ":" + todayDate.getMinutes();

        var lineaActual = this.props.match.params;
        var primeraParada = this.state.datosParadas[0];

        if(lineaActual !== undefined && primeraParada !== undefined){

            var directionId = 0;

            if(primeraParada.directionId !== undefined){
	           directionId = primeraParada.directionId;
	        }
             
            apiLineas.getHorarios(lineaActual.operador, lineaActual.idRoute, primeraParada.stopId, directionId, date).then((horarios) => {

                var horarioInicio = "";
                var horarioFin = "";
                if(horarios.contenido!=="undefined"){ 
	                if(horarios.contenido.length > 0){
	                    horarioInicio = horarios.contenido[0].arrivalTime;
	                    horarioFin = horarios.contenido[horarios.contenido.length-1].arrivalTime;
	                    horarioInitFin = t('keys.horariosLineas')+horarioInicio.replace(/:[^:]*$/,'') +" - "+horarioFin.replace(/:[^:]*$/,'');
	                    this.setState({
	                        horarioInitFin: horarioInitFin
	                    });
	                }
                }
            });
        }
	}

    enableLink = (stopId, lat, lng) => {

		var elementClicked = document.getElementById('link_parada_'+stopId);
       	for (var li of document.querySelectorAll("li.active")) {
		    li.classList.remove("active");
		}
       	elementClicked.classList.add("active");
		this.setState({
			isOpen: stopId,
			mapCenter: {lat: lat, lng: lng},
			defaultZoom: 14
		 });
	}

	onToggleOpen() {

		this.setState({
			isOpen: ""
		 });
	}
	
	
	getLetra(){
		const unique = (value, index, self) => {
			  return self.indexOf(value) === index
		}
		
		let content = [];
		
		for(var j=0; j< this.state.datosParadas.length; j++){
			content.push(this.state.datosParadas[j].stopName.charAt(0))
			 
		}
		const uniqueTitle = content.filter(unique) 
		 
		return uniqueTitle.sort();
	}
	
	changeDirection() {
	
		this.state.dir = 1 - this.state.dir;
		apiLineas.getParadasSecuencial(this.props.match.params.operador, this.props.match.params.idRoute, this.state.dir, "").then((paradas) => {
			this.setState(() => ({
		   		datosParadas: paradas.contenido
            }), function () {
                for (var li of document.querySelectorAll("li.active")) {
	                li.classList.remove("active");
	            }
                this.checkAvaibleSchedule();
            });	
		});
	}
	
	checkAlert(operator, line) {
		const regex = /[^\w.\s]/gi;
	    
	    liferayAPI.checkAlerta(this.props.i18n.language, process.env.REACT_APP_SCTRUCTURE_ID_ALERTAS, line).then(response => {
			if(response.items.length > 0) {
            	var operador = "";
            	var possibleLines = "";
            	var isCorrectAlert = false;
            	
            	for (let i = 0; i < response.items.length; i++) { 
            		operador = response.items[i].contentFields[5].contentFieldValue.data.replace(regex, "");
                	possibleLines = response.items[i].contentFields[6].contentFieldValue.data;
                	var possibleLinesArray = possibleLines.split(',');
                	for (let j = 0; j < possibleLinesArray.length; j++) { 
                		if (operator.toUpperCase() === operador.toUpperCase() && possibleLinesArray[j] === line) {
	                		this.setState({
			            		hasAlert: true,
			            		alert: response.items[i]
			            	});
                			break;
                		}
                	}
            	}
			}
		});
	}
	 
	someFunc () { 
	    //using, for example as:
	    this._map.getCenter() 
	    this._map.setZoom(2);
	}
	
    render() {

        const {t} = this.props;
        let styles = [
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                      "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.icon",
                "stylers": [
                    {
                      "visibility": "on"
                    }
                ]
            }
        ];

		let title; 
		
		if (this.state.horarioInitSelected !== "") {
            var spanHorarioInit = <span className="first_time">{this.state.horarioInitSelected}</span>;
            var spanHorarioFin = <span className={this.state.horarioFinSelected === "" ? "second_time hidden" : "second_time"}>{this.state.horarioFinSelected}</span>;
        }

        let paradas, alfabeticas;
        let lastIndex = this.state.datosParadas.length -1;
        
        if (this.state.datosParadas!== undefined && this.state.datosParadas.length !==0){
	    	
        	alfabeticas = this.getLetra().map((letra, index) =>(
        			<li className="nivel_1" key={index}>
        				<p className="letter">{letra}</p>
        				<ul className="nivel_2">
        				{
        					this.state.datosParadas.map((parada, indexP) =>(
        							
								letra === parada.stopName.charAt(0) &&
							
    							<li id={"link_parada_"+parada.stopId} className="parada" key={indexP}>
    							<button type="button" id={"button_parada_"+indexP} className="button_parada"  onClick={() => {this.showSelectedLineAlfabetic(parada, indexP, parada.stopId, parada.stopLat, parada.stopLon)}} >{parada.stopName}</button>
    			                <Link to={"/parada_detalle/"+this.props.match.params.operador+"/"+this.props.match.params.linea+"/"+this.props.match.params.idRoute+"/"+this.props.match.params.ruta.replaceAll("/","%2F")+"/"+this.props.match.params.routeType+"/"+parada.stopName.replaceAll("/","%2F")+"/"+parada.stopId+"/0/"+parada.stopLat+"/"+parada.stopLon+"/"+this.state.showHeadsign+"/"+this.state.twoWay} className="link">
    	                        	{t('keys.horarioCompleto')}
    	                                <img className="normal" src={IconRifgt} alt=""/>
    	                                <img className="hover" src={IconRifgtHover} alt=""/>
    	                         </Link>
    	                         { this.state.showHorarios === "true" &&
    	                            <div className="times">
    	                                {spanHorarioInit}
    	                                {spanHorarioFin}
    	                            </div>
    	                         }
    				            </li>
        					))
        				}
				        </ul>
        			</li>
        			
        	));
        	
            paradas = this.state.datosParadas.map((parada, index) =>(

                <React.Fragment key={index}> 
                    {
                    	
                        !this.state.isAlphabetic  && lastIndex !== index &&
		    			<li id={"link_parada_"+parada.stopId} className="item">
                            <div className="mark_line">
                                <div className="line"></div>
                            </div>
                            <div className="content">
                            <button type="button" id={"button_parada_"+index} className={"button_parada"}  onClick={() => {this.showSelectedLine(parada, index, parada.stopId, parada.stopLat, parada.stopLon)}} >{parada.stopName}</button>
                            <Link to={"/parada_detalle/"+this.props.match.params.operador+"/"+this.props.match.params.linea+"/"+this.props.match.params.idRoute+"/"+this.props.match.params.ruta.replaceAll("/","%2F")+"/"+this.props.match.params.routeType+"/"+parada.stopName.replaceAll("/","%2F")+"/"+parada.stopId+"/"+(parada.directionId !== undefined ? parada.directionId : 0)+"/"+parada.stopLat+"/"+parada.stopLon+"/"+this.state.showHeadsign+"/"+this.state.twoWay} className="link">
                            {t('keys.horarioCompleto')}
                                    <img className="normal" src={IconRifgt} alt=""/>
                                    <img className="hover" src={IconRifgtHover} alt=""/>
                                </Link>
                            </div>
                            { this.state.showHorarios === "true" &&
	                            <div className="times">
	                                {spanHorarioInit}
	                                {spanHorarioFin}
	                            </div>
	                        }
                        </li>
                    }
                    {
                        !this.state.isAlphabetic  && lastIndex === index &&
                        <li className="item fin">
                             <div className="mark_line">
                                 <img src={IconEndLinea} alt=""/>
                             </div>
                             <div className="content">
                                 <p className="text_end">{parada.stopName}</p>
                             </div>
                         </li>
                    } 
       
                </React.Fragment>
            ));
        }
        
    return(

        <React.Fragment>
            <Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.lineas')} onSelectLanguage={this.handleLanguage}/>
                <div className="section_detail_line container" id="section_detail_line">
                    <div className="row reverse">
                        <div className="content_left col-12 col-lg-4">
                            {/* Página detalle de una línea */}
                            <div className="content_tabs_line" id="content_tabs_line">
                                {/* Cabecera */}
                                <div className={this.state.version+ " cabecera_detail_linea"} id="main-content">
                                    <h1 className="sr-only no_mobile">{t('keys.lineas')}</h1>
                                    <Link to={"/lineas_horarios"}
                                     className="back_planificador">
                                        <span className="material-icons">arrow_back</span>
                                        <span className="sr-only">{t('keys.volver')}</span>
                                    </Link>
                                    <div className="container">
                                        <ul className="list_anclas">
                                            <li>
                                                <Link to="horarios"  className="link active" onClick={(text) =>{this.changeStyle("horarios"); document.getElementById('section_detail_line').classList.remove('ocultar_mapa');}} id="horariosLi">
                                                    <span className="image" aria-hidden="true">
                                                        <img className="normal" src={IconHorariosLineas} alt=""/>
                                                        <img className="hover" src={IconHorariosLineasRed} alt=""/>
                                                    </span>
                                                    <span className="text">{t('keys.horarios')}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="tarifas"  className="link" onClick={(text) =>{this.changeStyle("tarifas"); document.getElementById('section_detail_line').classList.add('ocultar_mapa');}} id="tarifasLi">
                                                    <span className="image" aria-hidden="true">
                                                        <img className="normal" src={IconTarifasLineas} alt=""/>
                                                        <img className="hover" src={IconTarifasLineasRed} alt=""/>
                                                    </span>
                                                    <span className="text">{t('keys.tarifas')}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="ayuda" className="link" onClick={(text) =>{this.changeStyle("ayuda"); document.getElementById('section_detail_line').classList.add('ocultar_mapa');}} id="ayudaLi">
                                                    <span className="image" aria-hidden="true">
                                                        <img className="normal" src={IconAyudaLineas} alt=""/>
                                                        <img className="hover" src={IconAyudaLineasRed} alt=""/>
                                                    </span>
                                                    <span className="text">{t('keys.ayuda')}</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Sección horarios */}
                                <section className="section_linea_horarios hidden" id="horarios">
                                <div className="list_horarios">
                            	<div className="linea_horario">
                                	<h2 className="title">
                                		{this.props.match.params.operador}
                                		 	{
                                		 		this.props.match.params.linea !==" " && this.props.match.params.linea !=='""' &&
                                		 		<span className="line">{this.props.match.params.linea}
                                		 			{this.state.alert !== null && this.state.hasAlert &&
		                                		 		<Link to={"/alertas_detalle/"+this.state.alert.key}>
															<img className={this.state.hasAlert ? "" : "hidden"} src={IconAlarmLine} alt=""/>
														</Link>
													}
                                		 		</span>
                                		 	}
                                    </h2>
                                    <div className="info_linea">
                                    	{
                                			this.props.match.params.routeType === "3" &&
                                			<span className="material-icons" title={t('keys.bus')} aria-hidden="true">directions_bus</span>
                                		}
                                    	{
                                			this.props.match.params.routeType === "2" &&
                                			<span className="material-icons" title={t('keys.tren')} aria-hidden="true">directions_transit_filled</span>
                                		}
                                    	{
                                			this.props.match.params.routeType === "1" &&
                                			<span className="material-icons" title={t('keys.metro')} aria-hidden="true">directions_railway</span>
                                		}
                                    	{
                                			this.props.match.params.routeType === "7" || this.props.match.params.routeType === "5" &&
                                			<span className="material-icons" title={t('keys.tranvia')} aria-hidden="true">tram</span>
                                		}

                                    	<div className="text">
                	                    	<h3 className="subtitle" id="rutaHorario">{this.props.match.params.ruta.replaceAll("%2F","/")}</h3>
                	                    	
                                            <p className="horario">{this.state.horarioInitFin}</p>
                	                    	{ 
                	                    		this.state.seeMap !== "" &&
                	                    	
	                                            <button type="button" className="see_map" data-toggle="modal" data-target="#openMapaLinea">
	                	                    		{t('keys.mapaRecorrido')}
	                	                    	</button>
                	                    	}
                                    	</div>
                                    </div>
                                    {
                                    	this.state.showChangeDirection === "true"  &&
                                        <button type="button" className="button_change_direccion" onClick= {() => {this.changeDirection()}}>
                                            <img src={IconVuelta} alt=""/>
                                            <span className="sr-only">{t('keys.cambiarDireccion')}</span>
                                        </button>
                                    }
                                </div>

                			    <div className="paradas_linea">
                			        {
                			        	!this.state.isAlphabetic  &&
                			        	<ul className="list_paradas" id={"paradas"+this.props.match.params.linea.replaceAll(" ","")}>
                			            	{paradas}
                			           </ul>
                			        }
                			        {
                			        	this.state.isAlphabetic  &&
                			        	<ul className="list_abc_paradas">
                			        		{alfabeticas}
                			             </ul>
                			          }

                			    </div>
                			    {
                			    	this.state.downloadMap !== "" &&
                			    
	                			    <div className="section_dowdload_linea">
	                			    		<a className="link" href={process.env.REACT_APP_URL+this.state.downloadMap} target="_blank" rel="noopener noreferrer">
	                			            	{t('keys.descargarMapa')}
	                			            	<img className="icon" src={IconDownload} alt=""/>
	                			            </a>
	                			    </div>
                			    }
                			</div>
                                </section>

                                {/* Tarifas */}
                                <section className="content_tarifas section_linea_horarios hidden" id="tarifas">
                                	<SeccionTarifas operador={this.props.match.params.operador} routeType={this.props.match.params.routeType} linea={this.props.match.params.linea} ruta={this.props.match.params.ruta} seeMap={this.state.seeMap} pageView={"si"}/>
                                </section>

                                {/* Ayuda y contacto */}
                                <section className="content_ayuda_contacto section_contacto hidden" id="ayuda">
                                	<SeccionAyuda operador={this.props.match.params.operador} routeType={this.props.match.params.routeType} title={title} pageView={"si"}/>
                                </section>

                                {/* Bloque guardar y compartir */}
                                <div className="section_save_rute">
                                    <button className="button_save" type="button" data-toggle="modal" data-target="#modalOpenLineaGuardada" onClick= {() => {this.saveLine(this.props.match.params.operador, this.props.match.params.linea)}} >{t('keys.guardarLinea')}</button>
                                    <button className="button_share" type="button" data-toggle="modal" data-target="#modalOpenCompartirLinea">
                                        <span className="sr-only">{t('keys.compartirLinea')}</span>
                                        <span className="material-icons" aria-hidden="true">share</span>
                                    </button>
                                </div>

                                {/* Modal compartir linea */}
                                <CompartirLinea section="lineas" operador={this.props.match.params.operador} line={this.props.match.params.linea.trim() !== "" ? this.props.match.params.linea : this.props.match.params.ruta}/>

                                {/* Modal guardar linea */}
                                <GuardarLineas/>

                                {/* Modal ver linea */}
                                <div className="modal_bm big modal fade" id="openMapaLinea" tabIndex="-1" role="dialog" aria-labelledby="openMapaLinea" aria-hidden="true">
                                  <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">

                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <img src={IconClose} alt=""/>
                                        </button>

                                        <div className="body_modal">
                                            <h2 className="title">{t('keys.mapaRecorrido')}</h2>
                                            <div className="content_image">
                                            	{  
                                            		this.state.seeMap !== "" && 
                                            		<img src={process.env.REACT_APP_URL+this.state.seeMap} alt={t('keys.mapaRecorrido')}/>
                                            	}
                                                
                                            </div>
                                        </div>

                                     </div>
                                  </div>
                                </div>
                            </div>

                        </div>

                        {/* Mapa */}
                        <div className="mapa_linea col-12 col-lg-8">
							{this.state.bounds !== null &&
								
                            <GoogleMap
                                ref={(ref) => {this.state.map = ref;}} 
                               	center={this.state.mapCenter}                                 
                                zoom={this.state.defaultZoom}
                       
                                options={{
                                  gestureHandling:'greedy',
                                  mapTypeControl: false,
                                  streetViewControl: false,
                                  clickableIcons: true,
                                  zoomControl: false,
                                  fullscreenControl:true,
                                  styles: styles
                                }} 
                                onLoad={map => { 
                                	window.setTimeout(() => {
                                		map.fitBounds(this.state.bounds);
                                	    }, 50);
                                	
                                }}
                                // optional
                                onUnmount={map => {
                                	console.log(map.fitBounds)
                                }}
                            >
                            {this.state.datosParadas.map(parada => (
                                <Marker 
					            key={`marker-${parada.stopId}`}
					            name={parada.stopName}
					            position={{lat: parada.stopLat,lng: parada.stopLon}}
					            onClick= {() => {this.enableLink(parada.stopId, parada.stopLat, parada.stopLon)}}
					          >
					          {this.state.isOpen === parada.stopId && <InfoWindow
					          	onCloseClick={this.onToggleOpen} 
						        position={{lat: parada.stopLat,lng: parada.stopLon}}
						        key={`infowindow-${parada.stopId}`}
						        visible={false}>
						        <span id="paradasNombre">{parada.stopName}</span>
						      </InfoWindow>}
					          </Marker>
					        ))}
                            </GoogleMap>
							}
                        </div>
                    </div>
                </div>
                <Footer />
        </React.Fragment>

    );
  }
}
export default  withTranslation()(LineasInfoDetalleComponent);
