import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'; 
import parse from 'html-react-parser';
import liferayAPI from '../../apiLiferay';

import { HashLink as Link } from 'react-router-hash-link';
import IconBus from '../../images/icons/Icon_bus.svg';

import IconTransporteBizkaibus from '../../images/icons/Icon_transporte_bizkaibus.svg';
 
class TransportePublicoComponent extends Component {
	
	constructor(props) {
	    super(props);

	    this.state = { 
	    		contenidosLiferay: [],
	    		version: "app_trasportePublico",
	    		locale: "eu_ES"	  
	    };
	  }
  
	
	componentDidMount() {
		 
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_TRANSPORTES).then(response =>  
			this.setState({contenidosLiferay: response})
		);
	}
	
	render() {
		const {t} = this.props;
		let contenidoL = [];
		let contenidoTransportes;
 
		if (this.state.contenidosLiferay !== undefined && this.state.contenidosLiferay.length !==0){
			
			contenidoL.push(this.state.contenidosLiferay)		  
			contenidoTransportes = contenidoL.map((contenido, index) => (
					contenido.contentFields.map((contentFields, indexC) =>
				  		contentFields.name==="fichas" && 
				  		<li className={contentFields.nestedContentFields[0].contentFieldValue.data==="Bizkaibus"?"show":""} key={indexC} id={contentFields.nestedContentFields[0].contentFieldValue.data.replace(" ","")}>
				  			<Link className="link" to={contentFields.nestedContentFields[5].contentFieldValue.data}  rel="noreferrer">
						        <img className="icon" src={IconTransporteBizkaibus} alt=""/>
						        <span>{contentFields.nestedContentFields[0].contentFieldValue.data}</span>
						        {
						        	contentFields.nestedContentFields[0].contentFieldValue.data==="Bizkaibus" && 
						        	<img className="image" src={IconBus} alt=""/>
						        }
						    </Link>
					    </li> 
					)
			)); 
	    }
    return(
  
    	<React.Fragment>
    		<h2 className="title" id="todo_transporte">{parse(t('keys.todoTransporte'))}</h2>
    		<p className="description">{t('keys.transportePulsa')}</p>
    		<ul className="list_transporte">
    			{contenidoTransportes}
    		</ul>
    	</React.Fragment>
    		
    );
  }
}
export default withTranslation()(TransportePublicoComponent);
