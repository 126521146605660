import React, { Component } from 'react'; 
import { withTranslation } from 'react-i18next'; 
import parse from 'html-react-parser';
import liferayAPI from '../../apiLiferay';

import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';
import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';


class TitulosTransportesComponent extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
    		contenidosLiferay: [],
	    	version: "app_titulosTarifas",
	    	open:false,
	   	    locale: "eu_ES"
	    };
	}
	
	handleLanguage = (langValue) => {
		this.setState({locale: langValue});
	}
	
	componentDidMount() {
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}
		
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_TITULOS).then(response =>  
			this.setState({contenidosLiferay: response})
		);
	}
	
	render() {
		const {t} = this.props;
		let contenidoTitulosTarifas;
		let contenidoL = [];
	  
		if (this.state.contenidosLiferay !== undefined && this.state.contenidosLiferay.length !==0){
			contenidoL.push(this.state.contenidosLiferay)		  
			contenidoTitulosTarifas = contenidoL.map((contenido, index) => (
					contenido.contentFields.map((contentFields, index) => 
				  		contentFields.name==="fichas" &&
				  		<li className="item" key={index}>
							<div className="item_head" id={"heading"+index}>		 
				        	<h3 className="subtitle">
					        	<button type="button" id={"button"+index} className="collapsed" data-toggle="collapse" data-target={"#titulos"+ index} aria-expanded={this.state.open} aria-controls={"titulos"+index} >
					        		<span className="text">{contentFields.nestedContentFields[0].contentFieldValue.data}</span>
					        		<span className="material-icons arrow" aria-hidden="true">expand_more</span>
							   	</button>	
						    </h3>	
							</div>
							<div id={"titulos"+index} className="content_body collapse" aria-labelledby={"heading"+index} data-parent="#accordion" >
						    	<div className="item_body">
						      		{parse(contentFields.nestedContentFields[4].contentFieldValue.data)}
						      		{
						      			contentFields.nestedContentFields[5].contentFieldValue.data !=="" &&
						      		
							      		<a className="link_more" href={contentFields.nestedContentFields[5].contentFieldValue.data} target="_blank" rel="noopener noreferrer">
									        {t('keys.verMas')}
									        <img className="normal" src={IconRifgt} alt=""/>
					                        <img className="hover" src={IconRifgtHover} alt=""/>
					                    </a>
						      		}
				                </div> 
			               </div>
		               </li>
					)
			)); 
	    }
    return(
    		<React.Fragment>
    			<h2 className="title" id="titulos_transporte">{parse(t('keys.titulosTransportes'))}</h2>
    			<ul id="accordion" className="section_collapse">
    				{contenidoTitulosTarifas}
    			</ul> 
    		</React.Fragment>
    );
  }
}
export default withTranslation()(TitulosTransportesComponent);
