import React, { Component } from 'react';
 import { withTranslation } from 'react-i18next';

import parse from 'html-react-parser';
import liferayAPI from '../../apiLiferay';

import Header from '../general/Header';
import Footer from '../general/Footer';

import AcessoRapido from '../general/AccesoRapidoComponent';
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class MugituDesarrolladoresComponent extends Component {
	constructor(props) {
	    super(props);
 
	    this.state = {  
	      version: "app_mugituDesarrolladores",
	      view: "no_list",
	      showSelections: "none",
	      contenidosLiferay:[],
	      locale: "eu_ES"
	    	  
	    };
	  }
	
	 componentDidMount(){
		const cookies = new CookiesUniversal();
	 	if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
		}
		this.cookies()
		if(window.scrollY !== 0){
			window.scrollTo( 0, 0 );
		}
		 
		if(document.getElementById('planificador')!==null && document.getElementById('lineas')!==null){
			document.getElementById('planificador').classList.remove('active');document.getElementById('lineas').classList.remove('active');  
		}
		 
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_DESARROLLADORES).then(response => 
			this.setState({contenidosLiferay: response})
		);
	}
	 
	cookies(){
		var {t} = this.props;
		   	
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 		 
		const cookies = new CookiesUniversal();
		 
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
 
		if(cookieAnalitica ==="true"){
				ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
//				ReactGA.pageview(pathname, ['defaultTracker'], t('keys.mugituDesarrolladores'));
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.mugituDesarrolladores'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
				ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
//				ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.mugituDesarrolladores'));
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.mugituDesarrolladores'), tracking: 'anonymousTracker' });

		}
	}
	 
	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
		this.setState({ view: view});
	}
	
	handlerMapSelectionToogle = () => {
		if(this.state.showMapSelections === "none"){
	      this.setState({ showMapSelections: "block" });
	    }else{
	      this.setState({ showMapSelections: "none" });
	    }
	}

  render() {
	  const {t} = this.props;
	  
	  return(
    		 <React.Fragment>
				<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.mugituDesarrolladores')} cabecera="true" onSelectLanguage={this.handleLanguage}/>
	    		<div className={this.state.version} id="main-content">

	    		<div className={this.state.version+ " cabecera_desarrolladores"}>
                    <div className="background"></div>
                    <h1 className="title">{t('keys.mugituDesarrolladores')}</h1>
                </div>

                <div className="section_page_common">
                    <div className="section_desa container">
                        
                        {
							this.state.contenidosLiferay !== undefined && this.state.contenidosLiferay.length !==0 &&
							parse(this.state.contenidosLiferay.contentFields[0].contentFieldValue.data)
						}
                    </div>
                </div>

		    		
	    		</div>
	    		<AcessoRapido/>
	    		<Footer />
    		</React.Fragment>
    );
  }
}
export default withTranslation()(MugituDesarrolladoresComponent);
