import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import liferayAPI from '../../apiLiferay';

import PreguntasFrecuentes from '../ayuda/PreguntasFrecuentesComponent';
import OficinasCliente from '../ayuda/OficinasClienteComponent';

import IconTwitter from '../../images/icons/icon_twitter.svg';
import IconFacebook from '../../images/icons/icon_facebook.svg';
import IconInstagram from '../../images/icons/icons8-instagram.svg';

import IconContactoTime from '../../images/icons/icon_contacto_time.svg';
import IconContactoTel from '../../images/icons/icon_contacto_tel.svg';
import IconContactoMail from '../../images/icons/icon_contacto_mail.svg';

import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class SeccionAyudaComponent extends Component {
	constructor(props) {
	    super(props);

	    this.state = {  
	      contenidosLiferay:[],
	      facebook:IconFacebook,
		  twitter:IconTwitter,
		  instagram:IconInstagram, 
	      locale: "eu_ES"
	    	  
	    };
	  }
	
	componentDidMount() {
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    }
		liferayAPI.getArticlesContenidoLiferay(this.props.i18n.language, process.env.REACT_APP_STRUCTURE_ID_OPERADOR).then(response => 
			this.setState({contenidosLiferay: response.items})
		);		 
	}
	
	cookies(){
		var {t} = this.props;
		 
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 	 
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
 
		if(cookieAnalitica ==="true"){
//			ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuLineas')); 
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuLineas'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
//			ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuLineas'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuLineas'), tracking: 'anonymousTracker' });

		}
	}
	
    render() { 
    	
    	const {t} = this.props;
    	
    	if(this.props.pageView){
    		this.cookies()
    	} 
        
        const regex = /[^\w.\s]/gi;
    	let contenidoContacto;

		if (this.state.contenidosLiferay !== undefined && this.state.contenidosLiferay.length !==0){
			contenidoContacto = this.state.contenidosLiferay.map((contenido, index) => ( 
					contenido.contentFields[1].contentFieldValue.data.replace(regex, "") === this.props.operador.toString()&&
					contenido.contentFields[2].contentFieldValue.data.replace(regex, "") === this.props.routeType &&
					<React.Fragment key={index}>
						{
							contenido.contentFields[4].contentFieldValue.data !=="" &&
							<li className="item text">
								<div className="icon">
					        		<img src={IconContactoTime} alt=""/>
						       	</div>	
					        	{parse(contenido.contentFields[4].contentFieldValue.data)}
				        	</li>
						}
						{
							contenido.contentFields[4].nestedContentFields[0].contentFieldValue.data !=="" &&
							<li className="item">
				        		<div className="icon">
									<img src={IconContactoTel} alt=""/>
								</div>
								<p className="tel" id="Telefono"><a href={"tel:"+contenido.contentFields[4].nestedContentFields[0].contentFieldValue.data}>{contenido.contentFields[4].nestedContentFields[0].contentFieldValue.data}</a></p>
							</li>
						}
						{
							contenido.contentFields[4].nestedContentFields[1].contentFieldValue.data !=="" &&
							<li className="item">
								<div className="icon">
					            	<img src={IconContactoMail} alt=""/>
						        </div>
			                    <p id="Email"><a href={"mailto:"+contenido.contentFields[4].nestedContentFields[1].contentFieldValue.data}>{contenido.contentFields[4].nestedContentFields[1].contentFieldValue.data}</a></p>
		                    </li>
						}
						{
							contenido.contentFields[4].nestedContentFields[2].contentFieldValue.data !== "" &&
							
							<li className="rrss">
						        {
						        	contenido.contentFields[4].nestedContentFields.map((rrss, indexR)=>(
						        		rrss.name==="rrss" &&
						        		<a className="link" href={rrss.contentFieldValue.data} target="_blank" rel="noopener noreferrer" title={t('keys.enlaceOtraPagina')} key={indexR}>
				                           	<img src={rrss.contentFieldValue.data.includes("facebook")?this.state.facebook:rrss.contentFieldValue.data.includes("twitter")?this.state.twitter:this.state.instagram} alt=""/>
				                           	<span className="sr-only">{rrss.contentFieldValue.data.includes("facebook")?"Facebook":rrss.contentFieldValue.data.includes("twitter")?"Twitter":"Instagram"}</span>
				                        </a>
						        	))
						        }	                        
			                </li>
						}
					    
	    			        
	                </React.Fragment>
					
			));    	
		}
     return(
    		
        <React.Fragment>
	        <div className="container_desktop">
		        <div className="section_preguntas_frecuentes">
		            <h2 className="name_transporte">{this.props.title}</h2>
		            <PreguntasFrecuentes operador={this.props.operador.toString()}/>
		        </div>
		        <div className="section_contacta">
		            <h2 className="title">{t('keys.contacto')}</h2>
		            <ul className="list_contacta">
		               {contenidoContacto}
		            </ul>
		        </div>
		        <div className="section_office" id="section_office">
		            <OficinasCliente operador={this.props.operador.toString()}/>
		        </div>
	        </div>
        </React.Fragment>
    		
    );
  }
}
export default  withTranslation()(SeccionAyudaComponent);
