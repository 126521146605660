import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import liferayAPI from '../../apiLiferay';
import parse from 'html-react-parser';

import Header from '../general/Header';
import Footer from '../general/Footer';

import AcessoRapido from '../general/AccesoRapidoComponent';

import IconDownload from '../../images/icons/button_download_red.svg';
import ImageMapaTransporte from '../../images/icons/image_mapa_servicios.svg';
 
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class MapasComponent extends Component {
	constructor(props) {
	    super(props);
 
        this.state = {  
	      version: "mapas",
	      view: "no_list", 
	      showSelections: "none",
	      contenidosGeneralLiferay:[],
	      contenidosAutobusesLiferay:[],
	      contenidosMetroLiferay:[],
	      contenidosOtrosLiferay:[],
	      locale: "eu_ES"
	    	  
	    };
	}
	
	componentDidMount(){
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    } 
 		
		this.cookies();
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}
		
		if(document.getElementById('planificador')!==null && document.getElementById('lineas')!==null){
			 document.getElementById('planificador').classList.remove('active');
			 document.getElementById('lineas').classList.remove('active');  
		}	
	
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_GENERAL ).then(response => 
			this.setState({contenidosGeneralLiferay: response})
		);
	
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_AUTOBUS).then(response => 
			this.setState({contenidosAutobusesLiferay: response})
		);
 
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_METRO).then(response => 
			this.setState({contenidosMetroLiferay: response})
		);
  
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_OTRO).then(response => 
			this.setState({contenidosOtrosLiferay: response})
		);
	      
	}
	 
	cookies(){
		var {t} = this.props;
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
	     
		if(cookieAnalitica ==="true"){
			ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
//			ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuMapas'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuMapas'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
			ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
//			ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuMapas'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuMapas'), tracking: 'anonymousTracker' });

		}
	}

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
	    this.setState({ view: view}); 
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	}	
	 
	render() {
		const {t} = this.props;
		let contenidoGeneral = [];
		let contenidoMapaGeneral;
		
		if (this.state.contenidosGeneralLiferay !== undefined && this.state.contenidosGeneralLiferay.length !==0){
			  contenidoGeneral.push(this.state.contenidosGeneralLiferay)
			 
			  contenidoMapaGeneral = contenidoGeneral.map((contenido, index) =>(
					 <li className="item" key={index}>
					 		{ 
					 			contenido.contentFields[2].contentFieldValue.hasOwnProperty("image") &&
								 <a className="link" href={process.env.REACT_APP_URL+contenido.contentFields[2].contentFieldValue.image.contentUrl} download target="_blank" rel="noopener noreferrer">
								 	<span className="sr-only">{contenido.contentFields[0].contentFieldValue.data}</span>
								 </a>
					 		}
					 		{ 
					 			contenido.contentFields[6].contentFieldValue.hasOwnProperty("document")  &&
								<a className="link" href={process.env.REACT_APP_URL+contenido.contentFields[6].contentFieldValue.document.contentUrl} download target="_blank" rel="noopener noreferrer">
								 	<span className="sr-only">{contenido.contentFields[0].contentFieldValue.data}</span>
								 	</a>
					 		}
						  
						 <h2 className="title">{contenido.contentFields[0].contentFieldValue.data}</h2>
		                 <img className="icon" src={IconDownload} alt=""/>
		                 <img className="image" src={ImageMapaTransporte} alt=""/>
					 </li>
			  ));
		}
	
		let contenidosMapasAutobuses;
		let contenidosAutobuses = [];
		
		if (this.state.contenidosAutobusesLiferay !== undefined && this.state.contenidosAutobusesLiferay.length !==0){
			contenidosAutobuses.push(this.state.contenidosAutobusesLiferay)
			contenidosMapasAutobuses = contenidosAutobuses.map((contenido, index) =>(
					contenido.contentFields.map((contentFields, index) => 
						contentFields.name==="fichas" && 
			  			<li className={index===7?"item big":index===8 || index===9?"item medium":"item small"} key={index}>
				  			<a className="link" href={process.env.REACT_APP_URL+contentFields.nestedContentFields[6].contentFieldValue.document.contentUrl} download target="_blank" rel="noopener noreferrer">
				  				<span className="sr-only">{contentFields.nestedContentFields[0].contentFieldValue.data}</span>
					 	    </a>
						 	{
						 		contentFields.nestedContentFields[2].contentFieldValue.image !== undefined &&
		                        <div className="image">
		                           <img className="transport" src={process.env.REACT_APP_URL+contentFields.nestedContentFields[2].contentFieldValue.image.contentUrl} alt=""/>
		                       </div>
		                    }
						 	<h3 className="subtitle">{contentFields.nestedContentFields[0].contentFieldValue.data}</h3>
						 	<img className="icon" src={IconDownload} alt=""/>
			  			</li>
					)
			));
	    }
	  
		let contenidosMapasMetro;
		let contenidosMetro = [];
		
		if (this.state.contenidosMetroLiferay !== undefined && this.state.contenidosMetroLiferay.length !==0){
			contenidosMetro.push(this.state.contenidosMetroLiferay)
			contenidosMapasMetro = contenidosMetro.map((contenido, index) =>(
					contenido.contentFields.map((contentFields, index) => 				
			  			contentFields.name==="fichas" && 
			  			<li className="item" key={index}>
				  			<a className="link" href={process.env.REACT_APP_URL+contentFields.nestedContentFields[6].contentFieldValue.document.contentUrl} download target="_blank" rel="noopener noreferrer">
				  				<span className="sr-only">{contentFields.nestedContentFields[0].contentFieldValue.data}</span>
					 	    </a>
						 	{
						 		contentFields.nestedContentFields[2].contentFieldValue.image !== undefined &&
		                        <div className="image">
		                           <img className="transport" src={process.env.REACT_APP_URL+contentFields.nestedContentFields[2].contentFieldValue.image.contentUrl} alt=""/>
		                       </div>
		                    }
						 	<h3 className="subtitle">{contentFields.nestedContentFields[0].contentFieldValue.data}</h3>
						 	<img className="icon" src={IconDownload} alt=""/>
			  			</li>
					)
			));
	    }

	    let contenidosMapasOtros;
		let contenidosOtros = [];
		if (this.state.contenidosOtrosLiferay !== undefined && this.state.contenidosOtrosLiferay.length !==0){
			contenidosOtros.push(this.state.contenidosOtrosLiferay)
			contenidosMapasOtros = contenidosOtros.map((contenido, index) =>(
				contenido.contentFields.map((contentFields, index) => 				
					contentFields.name==="fichas" && 
				  		<li className="item" key={index}>
					  		<a className="link" href={process.env.REACT_APP_URL+contentFields.nestedContentFields[6].contentFieldValue.document.contentUrl} download target="_blank" rel="noopener noreferrer">
					  			<span className="sr-only">{contentFields.nestedContentFields[0].contentFieldValue.data}</span>
						    </a>
						 	{
						 		contentFields.nestedContentFields[2].contentFieldValue.image !== undefined &&
		                        <div className="image">
		                           <img className="transport" src={process.env.REACT_APP_URL+contentFields.nestedContentFields[2].contentFieldValue.image.contentUrl} alt=""/>
		                       </div>
		                    }
						 	<h3 className="subtitle">{contentFields.nestedContentFields[0].contentFieldValue.data}</h3>
						 	<img className="icon" src={IconDownload} alt=""/>
		  				</li>
					)
				));
		    }
	
    return(
		 <React.Fragment>
			<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuMapas')} cabecera="true" onSelectLanguage={this.handleLanguage}/>
	    	<div className="cabecera_mapas" id="main-content">
	    	    <div className="background"></div>
				<h1 className="title" >{t('keys.menuMapas')}</h1>
				<p className="subtitle">{parse(t('keys.mapaCabecera'))}</p>
			</div>
			<div className={this.state.version +" content_body_mapas"}>
			    <div className="container">
                    <div className="mapas_banners">
                        <ul className="list_maps">{contenidoMapaGeneral}</ul>
                    </div>
                    <div className="mapas_cards">
                    	<h2 className="title">{parse(t('keys.servicioAutobuses'))}</h2>
                    	<ul className="list_maps">{contenidosMapasAutobuses}</ul>
	                </div>
	                <div className="mapas_cards">
	                    <h2 className="title">{parse(t('keys.mapaMetroTrenTranvia'))}</h2>
	                    <ul className="list_maps">{contenidosMapasMetro}</ul>
	                </div>
	                <div className="mapas_cards">
	                    <h2 className="title">{parse(t('keys.otrosMapas'))}</h2>
	                    <ul className="list_maps">{contenidosMapasOtros}</ul>
	                </div>
                </div>
			</div>
			 <AcessoRapido/>
			 <Footer />
		</React.Fragment>
    		
    );
  }
}export default  withTranslation()(MapasComponent);
